<script lang="ts">
    import type { Product, Image } from '../../types'
    import { Button, Carousel, Heading } from 'flowbite-svelte'
    import { push, pop, replace } from 'svelte-spa-router'

    export let products: Product[] = []
    let imagePositionX: number = 0
    let activeProductIndex: number | null = null
    let lastClickedIndex: number | null = null
    let lastClickX: number = 0
    let speechBubble: HTMLElement

    function toggleDescription(index: number): void {
        activeProductIndex = activeProductIndex === index ? null : index
    }

    function handleClick(event: MouseEvent, index: number): void {
        lastClickedIndex = index
        lastClickX = event.clientX
        toggleDescription(index)
    }

    $: if (speechBubble && activeProductIndex === lastClickedIndex) {
        const rect = speechBubble.getBoundingClientRect()
        if (rect.width > 0 && rect.height > 0) {
            // Stellen Sie sicher, dass das Element sichtbar ist
            imagePositionX = lastClickX - rect.left
        }
    }

    function handleKeydown(event: KeyboardEvent, index: number): void {
        if (event.key === 'Enter' || event.key === ' ') {
            toggleDescription(index)
        }
    }

    $: imageStyle = `left: ${imagePositionX}px`

    const image: Image = {
        title: 'Sprechende Biene',
        description: 'Sprechende Biene',
        url: '/build/bubblebee.png'
    }

    function closeSpeechBubble() {
        activeProductIndex = null
    }
</script>

<div class="p-1 flex flex-wrap items-stretch justify-center">
    {#each products as product, index}
        <div
            class="flex-shrink-0 m-6 static w-full md:w-[calc(50%-48px)] lg:w-[calc(33.333%-48px)] flex flex-col h-full"
            tabindex="0"
            on:click="{(event) => handleClick(event, index)}"
            on:keydown="{(event) => handleKeydown(event, index)}"
            role="button"
            aria-pressed="{activeProductIndex === index}">
            <img src="{product.image.url}" alt="{product.image.description}" class="mx-auto !max-w-[180px]" />
            <div class="flex-grow">
                <h3 class="text-lg font-bold">{product.name}</h3>
                <p>{product.description}</p>
            </div>


            {#if activeProductIndex === index}
                <div bind:this="{speechBubble}" class="speech-bubble w-[70%] z-[102] fixed p-2 bg-white border border-gray-200 rounded-lg" style="top:25%; left: 50%; transform: translateX(-50%);">
                    {product.extendedDescription}
                    {#if product?.link?.href }
                        <Button href="{product.link.href}" class="block" color="green" on:click="{() => product.link && push(product.link.href)}">{product.link.title}</Button>
                    {/if}
                    <img src="{image.url}" alt="{image.description}" style="{imageStyle}" class="absolute -bottom-10 w-12 h-12" />
                </div>
            {/if}
        </div>
    {/each}
</div>
{#if activeProductIndex !== null}
    <div
        class="fixed inset-0 bg-black bg-opacity-50 z-[101]"
        on:click="{closeSpeechBubble}"
        on:keydown="{(e) => {
            if (e.key === 'Enter') closeSpeechBubble()
        }}">
    </div>
{/if}
