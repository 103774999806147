<script >
    import { onMount } from "svelte";
    import { acceptAll, acceptNecessary, showModal, cookieConsent, redirect } from "./component/stores/cookieCheker";

    let onlyNecessaryAccepted = false;

    // Überwachung des Cookie-Zustands
    $: $cookieConsent, onlyNecessaryAccepted = $cookieConsent.necessaryAccepted && !$cookieConsent.allAccepted;

    // Überprüfung, ob bereits ein Cookie für die Zustimmung gesetzt wurde
    onMount(() => {
        const consentGiven = localStorage.getItem('cookieConsent');
        if (!consentGiven) {
            showModal.set(true);  // Modal anzeigen, wenn keine Zustimmung vorhanden ist
        }
    });

    // Funktion für das Akzeptieren aller Cookies und Freigeben aller Routen
    function acceptAllCookies() {
        acceptAll();
        localStorage.setItem('cookieConsent', 'all');  // Speichere die Zustimmung in localStorage
        if($redirect.toLocation !== ""){
            window.location.hash = $redirect.toLocation
            redirect.set({ toLocation: ""})
        }
        showModal.set(false);  // Modal ausblenden
    }

    // Funktion für das Akzeptieren nur notwendiger Cookies (keine Shop-Routen)
    function acceptNecessaryCookies() {
        acceptNecessary();
        localStorage.setItem('cookieConsent', 'necessary');  // Speichere die Zustimmung in localStorage
        showModal.set(false);   // Modal ausblenden
    }

    let modal = false;

    showModal.subscribe(value => {
        modal = value;
    });
</script>

<!-- Main modal -->
{#if modal}
<div id="default-modal" class="fixed inset-0 z-50 flex justify-center items-center w-full h-full bg-gray-900 bg-opacity-50">
    <div class="relative p-4 w-full max-w-2xl max-h-full">
        <!-- Modal content -->
        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <!-- Modal header -->
            <div class="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                    Cookie Einstellungen
                </h3>
            </div>
            <!-- Modal body -->
            <div class="p-4 md:p-5 space-y-4">
                {#if onlyNecessaryAccepted}
                    <div class="flex items-center space-x-2 bg-yellow-100 border border-red-400 p-4 rounded-lg">
                        <svg class="w-[48px] h-[48px] text-red-700 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 13V8m0 8h.01M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                          </svg>
                          
                       
                        <p class="text-base font-semibold text-red-700">
                            Du hast nur notwendige Cookies akzeptiert. Der Shop funktioniert jedoch nur, wenn <strong>alle Cookies akzeptiert</strong> werden. Bitte akzeptiere alle Cookies, um den Shop nutzen zu können.
                        </p>
                    </div>
                {:else}
       
                <p class="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                    Wir verwenden <strong>keine Tracking-Cookies</strong>. Für den Checkout und die Zahlungsdienste über Stripe sind jedoch <strong>notwendige Cookies</strong> erforderlich. Ohne diese Cookies kann der Shop nicht korrekt funktionieren.
                </p>
                <p class="text-base leading-relaxed text-gray-500 dark:text-gray-400">
                    Möchtest du <strong>alle Cookies akzeptieren</strong> oder nur die <strong>notwendigen</strong> zulassen?
                </p>
                {/if}
            </div>
            <!-- Modal footer -->
            <div class="flex items-center p-4 md:p-5 border-t border-gray-200 rounded-b dark:border-gray-600">
                <button on:click={acceptAllCookies} type="button" class="text-white bg-green-500 hover:bg-green-600 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:bg-green-700">Alle akzeptieren</button>
                <button on:click={acceptNecessaryCookies} type="button" class="py-2.5 px-5 ml-3 text-sm font-medium text-gray-900 focus:outline-none bg-gray-300 rounded-lg border border-gray-300 hover:bg-gray-500 focus:z-10 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Nur notwendige akzeptieren</button>
            </div>
        </div>
    </div>
</div>
{/if}
