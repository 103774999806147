<script>
    import { medusaCartState } from '../state/cartStore'
    import { checkoutState, setCheckoutState } from '../state/store'
    import { cookieConsent, showModal } from '../../component/stores/cookieCheker'
    import { quantity, sum } from '../utils/helpers'
    import CartView from './CartView.svelte'
    export  let dropdownOpen
    export  let showRightNav

 
    let userCart = []
    let isLoading
    let checkoutVisibility

    checkoutState.subscribe((state) => {
        checkoutVisibility = state
    })

    medusaCartState.subscribe((state) => {
        if (state.cart.items) {
            userCart = state.cart
            isLoading = true
        }
    })

    function setStatus(prop) {
        if ($cookieConsent.allAccepted) {
            showRightNav.set(false)
            dropdownOpen = false
            setCheckoutState(prop)
            return
        } 
        showModal.set(true)
    }
</script>

<span class="cart">
    <button class="flex" on:click="{() => {setStatus(true) }}"
        >Warenkorb
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"></path>
        </svg>
        ( {userCart?.items?.length > 0 ? userCart?.items.map(quantity).reduce(sum) : 0})</button>
</span>
