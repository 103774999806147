import { writable } from 'svelte/store'
import { createClient } from '../utils/client'
import { updateCheckoutStore } from './checkoutStore'
import type { Cart, Order, Swap } from '@medusajs/medusa/dist/index'

const medusaClient = createClient()

export const medusaCartState = writable({
    cart_id: localStorage.getItem('MEDUSA_CART'),
    cart: {}
})

export const medusaShipping = writable([])

export const medusaPayment = writable([])

export const medusaCartConfirmation = writable(<Cart | Order | Swap>(<unknown>[]))

const createMedusaCart = async () => {
    try {
        const { cart } = await medusaClient.carts.create()
        return cart
    } catch (e) {
        localStorage.removeItem('MEDUSA_CART')
        console.log(`Error creating cart: ${e}`)
    }
}

const saveMedusaCartId = async () => {
    try {
        const newCart = await createMedusaCart()

        const stringCartObj = JSON.stringify({ cart_id: newCart?.id })
        localStorage.setItem('MEDUSA_CART', stringCartObj)

        medusaCartState.update((state) => {
            state.cart_id = stringCartObj

            return state
        })
    } catch (e) {
        console.log(`Error saving cart ID: ${e}`)
    }
}

export const retrieveCartId = () => {
    let cartId: string | null = null

    medusaCartState.subscribe((state) => {
        if (state.cart_id) {
            const { cart_id } = JSON.parse(state.cart_id)
            cartId = cart_id
        }
    })

    return cartId
}

export const handleStoreCart = async () => {

    const savedCartString = localStorage.getItem('MEDUSA_CART') || "{}";
    let savedCartId: { cart_id?: string } = JSON.parse(savedCartString);
    // Prüfen, ob savedCartId die Eigenschaft "cart_id" hat
    if (!savedCartId?.cart_id) {
       console.log("cart_id existiert:", (savedCartId as any).cart_id);

        console.log('-> Creating cart ')

        await saveMedusaCartId()
    } else {
        //TODO handle if cart is removed create await saveMedusaCartId()
        const { cart_id } = savedCartId
        const { cart } = await medusaClient.carts.retrieve(cart_id)

        medusaCartState.update((state) => {
            state.cart = cart
            return state
        })
    }
}

export const addCartInfo = async (
    userDetailsObj: { address_1: any; address_2: any; city: any; country_code: undefined; first_name: any; last_name: any; phone: any; postal_code: any },
    email: any
) => {
    try {
        const cartId = retrieveCartId()

        if (!!cartId) {
            const { cart } = await medusaClient.carts.update(cartId, {
                shipping_address: userDetailsObj,
                billing_address: userDetailsObj,
                email: email
            })

            medusaCartState.update((state) => {
                state.cart = cart
                return state
            })

            updateCheckoutStore({
                currentStep: 'Delivery',
                hasSelectedShipping: false
            })
        } else {
            console.error('cartId is null')
        }
    } catch (e) {
        console.log(`Error adding cart info: ${e}`)
    }
}

export const addVariantToCart = async (variantId: any, quantity: number) => {
    try {
        const cartId = retrieveCartId()

        if (!!cartId) {
            await medusaClient.carts.lineItems.create(cartId, {
                variant_id: variantId,
                quantity: quantity
            })

            // refresh cart
            await handleStoreCart()
        } else {
            console.error('cartId is null')
        }
    } catch (e) {
        console.log(`Error adding cart variant: ${e}`)
    }
}

export const deleteVariantFromCart = async (lineId: string) => {
    try {
        const cartId = retrieveCartId()
        if (!!cartId) {
            const { cart } = await medusaClient.carts.lineItems.delete(cartId, lineId)

            medusaCartState.update((state) => {
                state.cart = cart

                return state
            })
        } else {
            console.error('cartId is null')
        }
    } catch (e) {
        console.log(`Error deleting cart variant: ${e}`)
    }
}

export const completeCartCheckout = async () => {
    try {
        const cartId = retrieveCartId()

        if (cartId === null) {
            console.error('cartId is null')
            return
        }
        const data = await medusaClient.carts.complete(cartId)

        medusaCartConfirmation.update((state) => {
            // response contains a nested data.data object
            state = data.data

            return state
        })
        localStorage.removeItem('MEDUSA_CART'),
        medusaCartState.update((state) => {
            state.cart = { items: [] }

            return state
        })

        await handleStoreCart()
    } catch (e) {
        console.log(`Error starting payment session: ${e}`)
    }
}

export const resetCart = async () => {
    // Cart has been checked-out. Reset all stored state.
    console.log('-> Resetting saved Medusa Cart ID')

    localStorage.removeItem('MEDUSA_CART'),
    medusaCartState.update((state) => {
        state.cart = { items: [] }

        return state
    })

    await handleStoreCart()
    if (typeof window !== 'undefined') {
        window.location.href = '/#/shop'
    }
    location.reload()
}
