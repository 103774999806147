<style>
    :global(.select-field) {
        width: 100%;
        height: 40px;
    }
</style>

<script>
    import { ErrorMessage, Field, Form } from 'svelte-forms-lib'
    import * as Yup from 'yup'
    import { addCartInfo, medusaCartState } from '../../state/cartStore'
    import { checkoutStore } from '../../state/checkoutStore'
    import { retrieveAllProducts } from '../../state/productStore'
    let informationLoader = true
    let cart
    let currentCheckoutState

    checkoutStore.subscribe((state) => {
        currentCheckoutState = state?.currentCheckoutStep
    })
    let country_code

    medusaCartState.subscribe((state) => {
        cart = state.cart
        if (state.cart.region) {
            // Standardland auswählen
            //country_code = state.cart.region.countries[0].iso_2;
            country_code = state?.cart?.billing_address?.country_code || 'de'
            informationLoader = false
        }
    })

    let Schema = Yup.object().shape({
        firstName: Yup.string().min(2, 'Vorname ist zu kurz').max(50, 'Vorname ist zu lang').required('Erforderlich'),
        lastName: Yup.string().min(2, 'Nachname ist zu kurz').max(50, 'Nachname ist zu lang').required('Erforderlich'),
        email: Yup.string().email('Ungültige E-Mail').required('Erforderlich'),
        address: Yup.string().required('Erforderlich').max(45, 'Adresslimit sind 45 Zeichen'),
        address2: Yup.string().nullable(true).max(45, 'Adresslimit sind 45 Zeichen'),
        postalCode: Yup.string().required('Erforderlich'),
        city: Yup.string().required('Erforderlich'),
        province: Yup.string().nullable(true),
        phoneNo: Yup.string()
    })
</script>

<Form
    validationSchema="{Schema}"
    initialValues="{{
        address: cart?.billing_address?.address_1 || '',
        firstName: cart?.billing_address?.first_name || '',
        lastName: cart?.billing_address?.last_name || '',
        city: cart?.billing_address?.city || '',
        country: cart?.billing_address?.country_code || '',
        postalCode: cart?.billing_address?.postal_code || '',
        phoneNo: cart?.billing_address?.phone || '',
        email: cart?.email || '',
        address2: cart?.billing_address?.address_2 || ''
    }}"
    onSubmit="{async ({ address, address2, firstName, lastName, phoneNo, postalCode, email, city }) => {
        try {
            await addCartInfo(
                {
                    address_1: address,
                    address_2: address2,
                    city,
                    country_code: country_code,
                    first_name: firstName,
                    last_name: lastName,
                    phone: phoneNo,
                    postal_code: postalCode
                },
                email
            )
        } catch (e) {
            console.log(e)
        }
    }}">
    <h2 class="font-semibold text-2xl mb-5 mt-5 ml-2">Adresse</h2>

    <div class="mb-2 m-2">
        <Field
            class="shadow appearance-none border border-grey-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            id="firstName"
            placeholder="Vorname"
            type="text"
            name="firstName" />

        <ErrorMessage name="firstName" />
    </div>

    <div class="mb-2 m-2">
        <Field
            class="shadow appearance-none border border-grey-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            name="lastName"
            placeholder="Nachname"
            type="text" />

        <ErrorMessage name="lastName" />
    </div>

    <div class="mb-2 m-2">
        <Field
            class="shadow appearance-none border border-grey-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            name="email"
            placeholder="E-Mail"
            type="email" />
        <ErrorMessage name="email" />
    </div>

    <div class="mb-2 m-2">
        <Field
            class="shadow appearance-none border
                   border-grey-500 rounded
                   w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            name="address"
            placeholder="Adresse 1"
            type="text" />

        <ErrorMessage name="address" />
    </div>

    <div class="mb-2 m-2">
        <Field
            class="shadow appearance-none
                          border border-grey-500
                          rounded w-full py-2 px-3
                          text-gray-700 mb-3 leading-tight
                          focus:outline-none focus:shadow-outline"
            name="address2"
            placeholder="Adresse 2 (Optional)"
            type="text" />

        <ErrorMessage name="address2" />
    </div>

    <div class="mb-2 m-2">
        {#if informationLoader}
            <p>Lade verfügbare Länder</p>
        {:else}
            <select
                bind:value="{country_code}"
                on:change="{({ target }) => (country_code = target.value)}"
                class="cursor-pointer
                        shadow appearance-none
                        border border-grey-500 rounded
                        w-full py-2 px-3 text-gray-700
                        mb-3 leading-tight focus:outline-none
                        focus:shadow-outline"
                name="country">
                {#each cart.region.countries as { display_name, iso_2 }}
                    <option value="{iso_2}">
                        {display_name}
                    </option>
                {/each}
            </select>
        {/if}
    </div>

    <div class="flex flex-wrap m-2mb-2">
        <div class="w-full md:w-1/2 px-2 mb-3 md:mb-0">
            <Field
                class="shadow appearance-none border border-grey-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                name="postalCode"
                placeholder="Postleitzahl"
                type="number" />

            <ErrorMessage name="postalCode" />
        </div>
        <div class="w-full md:w-1/2 px-2">
            <Field
                class="shadow appearance-none border border-grey-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                name="city"
                placeholder="Stadt"
                type="text" />

            <ErrorMessage name="city" />
        </div>
    </div>

    <div class="mb-2 m-2">
        <Field
            class="shadow appearance-none border border-grey-500 rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
            name="phoneNo"
            placeholder="Telefon"
            type="number" />

        <ErrorMessage name="phoneNo" />
    </div>

    <button type="submit" class="custom-btn mb-2 m-2"> Weiter</button>
</Form>
