import { writable } from 'svelte/store'
import { createClient } from '../utils/client'
import { handleStoreCart, medusaCartState, retrieveCartId } from './cartStore'

const medusaClient = createClient()

export const medusaShipping = writable([])
export const medusaPayment = writable([])

import { type PricedProduct } from '@medusajs/medusa/dist/types/pricing'

export const medusaProductStore = writable({
    allProducts: [] as PricedProduct[],
    product: null as PricedProduct | null
})

export const retrieveAllProducts = async (): Promise<void> => {
    try {
        const { products, count } = await medusaClient.products.list()
        console.log(count)
        medusaProductStore.update((value) => {
            value.allProducts = products // Zugriff auf "products"
            value.product = null
            return value
        })
    } catch (e) {
        console.error(e)
    }
}

export const retrieveProduct = async (productId: string) => {
    try {
        const { product } = await medusaClient.products.retrieve(productId)
        medusaProductStore.update((value) => {
            value.product = product
            return value
        })
    } catch (e) {
        console.log(`Error fetching product: ${e}`)
    }
}

export const getShippingOptions = async () => {
    try {
        const cartId = retrieveCartId()
        //TODO CHECk if is true what i doo
        if (!cartId) {
            return
        }
        const data = await medusaClient.shippingOptions.listCartOptions(cartId)
        medusaShipping.update((state: any) => {
            state = data.shipping_options

            return state
        })
    } catch (e) {
        console.log(`Error retrieving shipping_id: ${e}`)
    }
}

export const setShippingMethod = async (shippingOptionId: any) => {
    try {
        const cartId = retrieveCartId() as unknown as string
        let newCart = await medusaClient.carts.addShippingMethod(cartId, {
            option_id: shippingOptionId
        })
        if (newCart && newCart.response && newCart.response.status === 200) {
            medusaCartState.update((state) => {
                state.cart = newCart.cart
                return state
            })
        } else {
            throw new Error('Error setting shipping method')
        }
    } catch (e) {
        console.log(`Error in set shipping method: ${e}`)
    }
}

export const startPaymentSession = async () => {
    try {
        const cartId = retrieveCartId() as unknown as string
        const data = await medusaClient.carts.createPaymentSessions(cartId)

        medusaPayment.update((state: any) => {
            state = data

            return state
        })
    } catch (e) {
        console.log(`Error starting payment session: ${e}`)
    }
}

export const setPaymentSession = async (paymentProvider: any) => {
    try {
        const cartId = retrieveCartId() as unknown as string

        await medusaClient.carts.setPaymentSession(cartId, {
            provider_id: paymentProvider
        })
    } catch (e) {
        console.log(`Error starting payment session: ${e}`)
    }
}
