<script>
    export let increaseQuantity = function () {}
    export let decreaseQuantity = function () {}
    export let orderCount
    export let maxQuantity
</script>

<div class="inline-block bg-white border rounded-[5px] min-w-[120px] h-12">
    <div class="flex items-center h-full">
        <!-- Minus Button, verhindert das Reduzieren unter 1 -->
        <button class="controls cursor-pointer px-4 text-xl" 
                on:click="{() => decreaseQuantity()}"
                disabled="{orderCount <= 1}">
            -
        </button>
        <div class="flex-1 text-center text-xl">
            {orderCount}
        </div>
        <!-- Plus Button, verhindert das Erhöhen über maxQuantity -->
        <button class="controls cursor-pointer px-4 text-xl" 
                on:click="{() => increaseQuantity()}"
                disabled="{orderCount >= maxQuantity}">
            +
        </button>
    </div>
</div>

<style>
    .controls {
        color: darkgrey;
        transition: all 250ms;
        font-size: 1.1em;
    }

    .controls:hover {
        cursor: pointer;
        color: #000;
    }

    .controls:disabled {
        color: lightgrey;
        cursor: not-allowed;
    }
</style>
