import { derived, writable } from 'svelte/store'

// Definiere den Artikel-Typ mit optionalen Eigenschaften
export interface Article {
    published: boolean
    slug: string
    image: {
        alt: string
        src: string
        title: string
    }
    imageCollection: {
        alt: string
        src: string
        title: string
    }[]
    type: string
    createdAt: string
    title: string
    content: string
    author: string
    authorAvatar: string
}



const initialArticles: Article[] = [
    {
        published: true,
        slug: 'shop_eroeffnet',
        image: {
            alt: 'Erste Honigernte',
            src: '/build/blog/honig_buehne.png',
            title: 'Erste Honigernte'
        },
        imageCollection: [
            {
                alt: 'Honiggläser',
                src: '/build/blog/honig_buehne.png',
                title: 'Gefüllte Honiggläser'
            }
        ],
        type: 'Artikel',
        createdAt: '2024-05-01',
        title: 'Der Shop ist jetzt geöffnet: Frischen Honig entdecken!',
        content: `
<p>Die erste Honigernte ist da! Unsere Bienen waren fleißig und haben köstlichen Honig produziert, den wir jetzt zum Verkauf anbieten. Besucht unseren Shop, um die ersten Gläser der Ernte zu erwerben!</p>
<br/>
<p>Wir haben lange auf diesen Moment gewartet, und es ist soweit. Unser frischer, regionaler Honig ist ab sofort verfügbar. Doch das ist erst der Anfang – wir planen, in Zukunft noch mehr Produkte anzubieten.</p>
<br/>

<div class="text-center">
<p><strong>Besucht unseren Shop:</strong></p>
<br/>
<p><a href="/#/shop" class="text-center font-medium focus-within:ring-4 focus-within:outline-none inline-flex items-center justify-center px-5 py-2.5 text-sm text-white bg-green-700 hover:bg-green-800 dark:bg-green-600 dark:hover:bg-green-700 focus-within:ring-green-300 dark:focus-within:ring-green-800 rounded-lg">Jetzt Honig kaufen</a></p>
</div>
<br/>
<p><strong>Wichtig:</strong> Weitere Produkte werden nächstes Jahr nach und nach hinzukommen. Seid gespannt und bleibt auf dem Laufenden!</p>
    `,
        author: 'Alexander Feier',
        authorAvatar: '/build/imker-avatar.svg'
    },
    {
        published: true,
        slug: 'die_reise_beginnt',
        image: {
            alt: 'Mein Start als Imker II',
            src: '/build/blog/v2/bienenstand.png',
            title: 'Mein Start als Imker II'
        },
        imageCollection: [
            {
                alt: 'Folto Sammlung am Bienenstock',
                src: '/build/blog/v2/bienenstand.png',
                title: 'Erster Tag am Bienenstock'
            },
            {
                alt: 'Bienenstand Imkerhelden',
                src: '/build/blog/v2/imker-helden.png',
                title: 'Ein Bienenstand der Imkerhelden'
            }
        ],
        type: 'Artikel',
        createdAt: '2024-04-19',
        title: 'Von der Faszination zur Leidenschaft: Meine Reise ins Imkern II',
        content: `
  <h1>Ein neues Kapitel: <br />Mein Weg mit den <a href="https://www.imkerhelden.de/">Imkerhelden</a></h1>
  <br/>
  <p>Nach meiner Begegnung mit den Bienen im Wildpark entschied ich mich, Imker zu werden. 
  Der <a href="https://www.imkerhelden.de/">Imkerhelden-Lehrgang</a> schien der perfekte Weg, mein Wissen zu vertiefen und sicherzustellen, dass es meinen Bienen gut geht. 
  Heute möchte ich euch von meinem ersten Tag am Bienenstock erzählen und meinem Dank an Dieter von den <a href="https://www.imkerhelden.de/">Imkerhelden</a> aussprechen.</p>
  <p>
  <br/>
  <img src="/build/blog/v2/20240302_121323.png" alt="Bild 1: Die Offene Beuthe">
  </p>
  <br/>
  <p>Der <a href="https://www.imkerhelden.de/">Imkerhelden-Lehrgang</a> begann vor ca. zwei Monaten und wird das ganze Jahr dauern. An meinem ersten Tag am Bienenstock war ich voller Vorfreude und Nervosität. 
  Dieter, unser Mentor von den Imkerhelden, führte uns durch die Grundlagen der Bienenpflege und ermutigte uns, die ersten Handgriffe zu wagen.</p>
  <p><br/><img src="/build/blog/v2/20240302_122323.png" alt="Bild 2: Die Waben des Bienenstocks"></p>
  <br/><p>Bilder von Waben, Futter und Brut eröffneten mir eine faszinierende Welt voller Leben und Aktivität. 
  Es war beeindruckend zu sehen, wie die Bienen zusammenarbeiteten, um ihr Volk zu versorgen und zu schützen.</p>
  
  <br/>
  <p>Mein erster Tag am Bienenstock war ein unvergessliches Erlebnis, das meine Begeisterung für die Imkerei nur noch verstärkt hat. 
  Ich danke Dieter von den <a href="https://www.imkerhelden.de/">Imkerhelden</a> für die Unterstützung und Anleitung auf diesem spannenden Weg. 
  Ich freue mich darauf, weiterhin von ihren Erfahrungen zu lernen und meine eigene Geschichte mit euch zu teilen.</p>
  <p> <br/><img src="/build/blog/v2/20240302_115703.png" alt="Bild 3: Zusammenarbeit im Bienenstock"></p>
  `,
        author: 'Alexander Feier',
        authorAvatar: '/build/imker-avatar.svg'
    },
    {
        published: true,
        slug: 'meine_reise_ins_imkern',
        image: {
            alt: 'Mein Start als Imker I',
            src: '/build/blog/mein_start_imker.png',
            title: 'Mein Start als Imker I'
        },
        imageCollection: [
            {
                alt: 'Folto Sammlung vom Besuch im Wildpark',
                src: '/build/blog/wildpark.png',
                title: 'Besuch im Wildpark'
            }
        ],
        type: 'Artikel',
        createdAt: '2024-04-05',
        title: 'Von der Faszination zur Leidenschaft: Meine Reise ins Imkern I',
        content: `
<p>Es war ein sonniger Tag im Herbst, als meine Frau, unser Hund Yoshi und ich die Pfade eines Wildparks erkundeten. Gemeinsam entdeckten wir eine kleine, aber faszinierende Ausstellung über Bienen. Die Komplexität ihres Soziallebens, ihre Rolle in unserem Ökosystem und der köstliche Honig, den sie produzieren, fesselten uns sofort. Dieser Moment des Staunens war der Funke, der in mir ein tiefes Interesse entfachte und mich auf den Weg brachte, Imker zu werden.</p>
<br/>
<p>In den darauffolgenden Monaten vertiefte ich mein Wissen über Bienen. Ich lernte über die Herausforderungen, denen sie heute gegenüberstehen, und die wichtige Rolle, die Imker bei der Unterstützung ihrer Populationen und der Förderung der Biodiversität spielen. Es wurde mir klar, dass ich nicht nur Zeuge dieser faszinierenden Welt sein wollte, sondern auch aktiv teilnehmen wollte.</p>
<br/>
<p>Mit einigen Bienenstöcken und großer Lernbereitschaft begann ich mein Abenteuer in der Imkerei. Jeder bisherige Tag bei den Bienen lehrte mich Geduld, Respekt vor der Natur und die Bedeutung von Nachhaltigkeit.</p>
<br/>
<p>Mein Blog „Specker Bienchen“ bietet einen Einblick in diese Welt. Ich teile alles, von praktischen Tipps für angehende Imker bis hin zu leckeren Rezepten mit Honig und der Bedeutung der Bienen für unsere Umwelt. Ich lade euch ein, mich auf dieser süßen Reise zu begleiten.</p>
<br/>
<p><strong>Macht mit:</strong></p>
<p>Habt ihr Fragen oder Themen, über die ihr mehr erfahren möchtet? Lasst es mich über Instagram #specker-bienchen in den Kommentaren wissen!</p>
    `,
        author: 'Alexander Feier',
        authorAvatar: '/build/imker-avatar.svg'
    }
]

export const allArticlesStore = writable<Article[]>(initialArticles)

export const articlesStore = derived(allArticlesStore, ($allArticlesStore) => {
    return $allArticlesStore.filter((article) => article.published)
})

export const previewArticleStore = writable<Article[]>(initialArticles)
