<script>
    import { onMount } from 'svelte'
    import { medusaProductStore, retrieveAllProducts } from '../../state/productStore'
    import { formatPrice } from '../../utils/helpers'
    import { cookieConsent } from '../../../component/stores/cookieCheker'
    let products
    let productsLoader = true

    medusaProductStore.subscribe((data) => {
        if (data?.allProducts[0]?.variants) {
            products = data.allProducts
            productsLoader = false
        }
    })
    onMount(() => {
        retrieveAllProducts()
    })
    let isAllAccepted = false
    $: $cookieConsent, isAllAccepted = $cookieConsent.allAccepted;
    $: if ($cookieConsent) {
        retrieveAllProducts();
    }
</script>

<div>
    <h2 class="text-xl my-12">Alle Produkte</h2>
    <hr />

    <ul class="flex flex-wrap justify-center gap-6">
        {#if productsLoader}
            <div>
                <p>Kommen bald ...</p>
            </div>
        {:else}
            {#each products as product, index}
                <li
                    class="transition-shadow ease-in-out duration-150 hover:shadow-lg flex flex-col items-center p-4
      w-full sm:w-[calc(50%-1rem)] lg:w-[calc(33.333%-1rem)]">

                    <a class="relative block text-decoration-none w-full" href="{`#/product/${product.id}`}">
                        <!-- Image with border -->
                        <div class="border border-gray-200 rounded-lg relative overflow-hidden aspect-[12/16] w-full">
                            <img
                                alt="{product.title} Thumbnail"
                                draggable="false"
                                loading="lazy"
                                decoding="async"
                                class="absolute inset-0 object-cover object-center h-full w-full"
                                srcset="
                  {product.thumbnail}?w=16&q=50 16w,
                  {product.thumbnail}?w=32&q=50 32w,
                  {product.thumbnail}?w=48&q=50 48w,
                  {product.thumbnail}?w=64&q=50 64w,
                  {product.thumbnail}?w=96&q=50 96w,
                  {product.thumbnail}?w=128&q=50 128w,
                  {product.thumbnail}?w=256&q=50 256w,
                  {product.thumbnail}?w=384&q=50 384w,
                  {product.thumbnail}?w=640&q=50 640w,
                  {product.thumbnail}?w=750&q=50 750w,
                  {product.thumbnail}?w=828&q=50 828w,
                  {product.thumbnail}?w=1080&q=50 1080w,
                  {product.thumbnail}?w=1200&q=50 1200w,
                  {product.thumbnail}?w=1920&q=50 1920w,
                  {product.thumbnail}?w=2048&q=50 2048w,
                  {product.thumbnail}?w=3840&q=50 3840w"
                                sizes="(max-width: 576px) 280px, (max-width: 768px) 360px, (max-width: 992px) 480px, 800px"
                                src="{product.thumbnail}?w=3840&q=50" />
                        <!-- Ausverkauft Batch -->
                        {#if product.variants.length === 1 && product.variants[0].inventory_quantity === 0}
                            <span class="absolute top-1/2 left-1/2 w-[200%] transform -translate-x-1/2 -translate-y-1/2 
                                bg-red-600/50 text-white text-center rotate-[-45deg] origin-[center] font-bold text-2xl py-1 px-4">
                                Ausverkauft
                            </span>
                        {/if}

                        </div>

                        <!-- Title and Price Row -->
                        <div class="flex justify-between items-center mt-4">
                            <h3 class="text-sm font-sans">{product.title}</h3>
                            <p class="text-sm font-sans text-gray-600">
                                {formatPrice(product.variants[0].prices[0].amount, product.variants[0].prices[0].currency_code)}
                            </p>
                        </div>
                    </a>
                </li>
            {/each}
        {/if}
    </ul>
</div>
